import React from "react";

const Menu = () => {
  return (
    <div
      style={{
        margin: "5px",
        height: "100vh",
        textAlign: "center",
        width: "auto"
      }}
    >
      <div>
        <img
          alt="menu1"
          src={"/menu1.PNG"}
          style={{ width: "100%", height: "auto" }}
        />
      </div>
      <div>
        <img
          alt="menu2"
          src={"/menu2.png"}
          style={{ width: "100%", height: "auto" }}
        />
      </div>
    </div>
  );
};

export default Menu;
